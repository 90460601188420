import React from "react" 

import Layout from "../components/layout"
import SEO from "../components/seo"
import quartz1 from "../assets/img/quartz/img-1.png"
import quartz2 from "../assets/img/quartz/img-2.png"
import signal from "../assets/img/quartz/signal.png"
import mopa from "../assets/img/quartz/mopa.png"


const QuartzPage = () => (
  <Layout>
    <SEO title="Solid Surface Quartz" /> 
    <div class="bg-white">
      <div class="grid grid-cols-1 md:grid-cols-2 mt-10 md:mx-40 mx-3 bg-white">
        <div>
          <h1 class="font-normal">Qu'est-ce que Silestone?</h1>
          <p class="text-center text-xs">
          Silestone est composé de 94% de quartz naturel, ce qui le rend extrêmement dur et résistant. C'est une surface parfaite pour les plans de travail cuisine et salle de bains, le revêtement sol et mural, le tout utilisant un nombre réduit de joints.
          </p>
        </div>
        <div>
          <img src={quartz1} alt="quartz" class="w-full m-0" />
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 flex items-center bg-white mt-8 md:mx-40 mx-3">
        <div>
        <img src={quartz2} alt="quartz" />
   
        </div>
        <div>
          <h1 class="font-normal text-center">A propos du quartz</h1>
           <p class="text-center text-xs">Silestone est une création en quartz naturel destinée à la décoration de la cuisine et de la salle de bains, dont le traitement offre une large gamme de couleurs, d'extraordinaires textures et des propriétés incroyables.</p>
        </div> 
      </div> 
      <div class="grid grid-cols-1 flex items-center mt-8 md:mx-40 mx-3 text-center">
        <h3 class="text-center font-normal">Résistance <span class="font-bold">aux taches</span></h3>
        <p class="w-1/3 text-sm text-center mx-auto">Quartz est une surface à faible porosité qui garantit une absorption nulle des liquide.</p>
        <img src={signal} alt="signal" class="mx-auto m-0" />
        <img src={mopa} alt="mopa" class="mx-auto m-0" />
        <h3 class="text-center font-normal mt-7">Assistant de couleur</h3>
        <p class="w-2/3 text-xs text-center mx-auto">SILESTONE EST DISPONIBLE DANS PLUS DE 90 COULEURS, ASSURANT AINSI QU'IL EXISTE UNE COULEUR ADAPTÉE POUR VOUS. AVEC 3 TEXTURES À ASSOCIER, POLIE, SUEDE ET VOLCANO, LES POSSIBILITÉS SONT INFINIES</p>

      </div>
    </div>
  </Layout>
)

export default QuartzPage
